<template>
  <main class="in-home">
    <TopSlider ref="topSlider" />
    <div class="main-back pb-4 pb-sm-5 mb-md-5">
      <TwoBanner />
      <div class="box-shaddow20 mb-4">
        <Recommendation
          v-if="withDiscounts"
          name="withDiscounts"
          :products="withDiscounts"
        />
        <FavoriteProducts />
      </div>
      <BannersSlider />
      <SpecialProducts :discount-products="discountProducts" />
      <TwoBannersGridInMobile />
      <ManyBannersGridMobile />
      <NewProducts :new-products="newProducts" />
    </div>
    <ManyBannersGrid />
    <Recommendation
      title1="پیشنهادی"
      title2="آلبا مارکت "
      v-if="recommendeds"
      name="recommendeds"
      :products="recommendeds"
    />
    <BestSellers :most-sold-products="mostSoldProducts" />
    <SpecialBrands v-if="special_brands" :special-brands="special_brands" />
    <NewArticles :new-blogs="newBlogs" />
  </main>
</template>
<script>
import Swiper from "swiper/swiper-bundle.js";
import "swiper/swiper-bundle.css";
import SpecialBrands from "./component/home/SpecialBrands";
import Recommendation from "./component/home/Recommendation";
import NewProducts from "./component/home/NewProducts";
import BestSellers from "./component/home/BestSellers";
import ManyBannersGridMobile from "./component/home/ManyBannersGridMobile";
import TwoBannersGridInMobile from "./component/home/TwoBannersGridInMobile";
import BannersSlider from "./component/home/BannersSlider";
import SpecialProducts from "./component/home/SpecialProducts";
import TopSlider from "./component/home/TopSlider";
import ManyBannersGrid from "./component/home/ManyBannersGrid";
import NewArticles from "./component/home/NewArticles";
import TwoBanner from "./component/home/TwoBanner";
import FavoriteProducts from "./component/home/FavoriteProducts";

export default {
  components: {
    FavoriteProducts,
    NewArticles,
    ManyBannersGrid,
    TopSlider,
    SpecialProducts,
    BannersSlider,
    TwoBannersGridInMobile,
    BestSellers,
    ManyBannersGridMobile,
    NewProducts,
    Recommendation,
    SpecialBrands,
    TwoBanner,
  },
  data() {
    return {
      blogs: null,
      discountProducts: {
        data: Array(6).fill(null),
      },
      newProducts: {
        data: Array(6).fill(null),
      },
      mostSoldProducts: Array(6).fill(null),
      newBlogs: Array(6).fill(null),
      special_brands: null,
      recommendations: null,
    };
  },
  computed: {
    withDiscounts() {
      return this.recommendations
        ? this.recommendations.with_discount.map((i) => i.product)
        : Array(6).fill(null);
    },
    recommendeds() {
      return this.recommendations
        ? this.recommendations.recommended.map((i) => i.product)
        : Array(6).fill(null);
    },
  },
  mounted() {
    this.fetchHome();
    this.initNewProducts();
    this.initDiscountProducts();
    this.initBestSeller();
    this.initBanners();
  },
  methods: {
    fetchHome() {
      this.$store.commit("showLoading");
      this.$axios
        .get("/api/home")
        .then((response) => {
          const data = response.data.data;
          this.blogs = data.blogs;
          this.$store.commit("header/setMenu", data.menus);
          this.$store.commit(
            "header/setUser",
            data["check-user"].data == null ? false : data["check-user"].data
          );
          this.$store.commit(
            "header/setCart",
            data["cart-items"].data.cartItems
          );
          this.$store.commit("header/setSlider", data.sliders);
          this.$store.commit("setSettings", data.settings);
          this.$store.commit("header/setCategories", data.categories);
          this.$store.commit("header/setBlogCategories", data.blog_categories);
          this.$store.commit("header/setPopularBlogs", data.popular_blogs);
          this.$store.commit("header/setBanners", data.banners);
          this.$store.commit("header/setFavorites", data.favorites);
          this.$store.commit("header/setBrands", data.brands);

          this.special_brands = data.special_brands;
          this.newProducts.data = data.latest_products.data;
          this.discountProducts.data = data.products_discount;
          this.newBlogs = data.blogs;
          this.mostSoldProducts = data.most_sold_products;
          this.mostSoldProducts = data.most_sold_products;
          this.recommendations = data.recommendation_groups;

          this.initNewProducts(true);
          this.initDiscountProducts(true);
          this.initBestSeller(true);
          this.initHeaderSlider(true);
          this.initBanners(true);
        })
        .catch(function(response) {
          //handle error
        })
        .finally(() => {
          this.$store.commit("hideLoading");
        });
    },
    initBanners(kill = false) {
      this.initSwiper("banners", kill, () => {
        if (this.bannersSwiper) {
          this.bannersSwiper.destroy();
        }
        this.bannersSwiper = new Swiper(".banner-slider .swiper-container", {
          spaceBetween: 30,
          //   centeredSlides: true,
          loop: false,
          speed: 1000,
          // autoplay: {
          //   delay: 3000,
          //   disableOnInteraction: false,
          // },
          pagination: {
            el: ".banner-slider .swiper-pagination",
            clickable: true,
          },
          breakpoints: {
            1: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            576: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 30,
            },
            992: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
          },
        });
      });
    },
    initNewProducts(kill = false) {
      this.initSwiper("newProducts", kill, () => {
        if (this.newProductsSwiper) {
          this.newProductsSwiper.destroy();
        }
        this.newProductsSwiper = new Swiper(".new-products .swiper-container", {
          spaceBetween: 30,
          //   centeredSlides: true,
          loop: false,
          speed: 800,
          autoplay: {
            delay: 3000,
            disableOnInteraction: false,
          },
          navigation: {
            nextEl: ".new-products  .swiper-button-next",
            prevEl: ".new-products  .swiper-button-prev",
          },
          breakpoints: {
            1: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            570: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            571: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
            992: {
              slidesPerView: 4,
              spaceBetween: 30,
            },
            1200: {
              slidesPerView: 5,
              spaceBetween: 30,
            },
          },
        });
      });
    },
    initDiscountProducts(kill = false) {
      this.initSwiper("discountProducts", kill, () => {
        if (kill) {
          setTimeout(() => {
            if (this.discountProductsSlider) {
              this.discountProductsSlider.destroy();
            }
            this.discountProductsSlider = new Swiper(
              ".discounted-products1 .swiper-container",
              {
                spaceBetween: 30,
                //   centeredSlides: true,
                loop: false,
                speed: 800,
                autoplay: {
                  delay: 3000,
                  disableOnInteraction: false,
                },
                navigation: {
                  nextEl: ".discounted-products1 .swiper-button-next",
                  prevEl: ".discounted-products1 .swiper-button-prev",
                },
                breakpoints: {
                  1: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                  },
                  570: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  571: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                  },
                  992: {
                    slidesPerView: 4,
                    spaceBetween: 30,
                  },
                  1200: {
                    slidesPerView: 5,
                    spaceBetween: 30,
                  },
                },
              }
            );
          }, 200);
        }
      });
    },
    initBestSeller(kill = false) {
      this.initSwiper("bestSeller", kill, () => {
        if (this.bestSellerSwiper) {
          this.bestSellerSwiper.destroy();
        }
        this.bestSellerSwiper = new Swiper(".best-sellers .swiper-container", {
          spaceBetween: 30,
          //   centeredSlides: true,
          loop: false,
          speed: 700,
          autoplay: {
            delay: 3000,
            disableOnInteraction: false,
          },
          navigation: {
            nextEl: ".best-sellers  .swiper-button-next",
            prevEl: ".best-sellers  .swiper-button-prev",
          },
          breakpoints: {
            1: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            570: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            571: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            1200: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
          },
        });
      });
    },
    initHeaderSlider(kill = false) {
      this.$refs.topSlider.initHeaderSlider(kill);
    },
  },
};
</script>
<style>
.in-home .discounted-products-img {
  height: 176px;
  width: 100%;
}
</style>
<style scoped>
.educational-content2 .toggling {
  display: none;
}

.bg-gray {
  background: url("/assets/images/bg-gray.png") repeat;
}
</style>
