<template>
  <section
      v-if="$store.state.header.banners != null && $store.state.header.banners.filter(item => item.position == 'a2')"
      class="big-banner container  my-3 mt-sm-5">
    <a v-for="(banner,index) in $store.state.header.banners.filter(item => item.position == 'a2')"
       :style="index == 0 ? 'margin-left:25px' : ''"
       :key="banner.id" target="_blank"
       :href="banner.link" class="main-big-banner">
      <img v-if="banner.image" :src="banner.image" :alt="banner.image_alt">
      <b-skeleton-img v-else></b-skeleton-img>
    </a>
  </section>
</template>

<script>
export default {
  name: "TwoBannersGridInMobile"
}
</script>


