<template>
  <div class="discounted-products p-3 border-radius15" :class="name">
    <div class="d-flex justify-content-between  mb-1">
      <div class="mt-1">
        <img src="../../../../assets/images/circle.png" alt="">
        <h3 style="font-size: 1.25rem" class="d-inline-block"><span
            class="weight-bold text-color-444 pr-2">{{ title1 }} </span> <span
            class="text-color-themeBlue fontsize20 font-weight-bolder weight-bold">{{ title2 }}</span></h3>
      </div>
      <div class="d-flex mt-1">
        <button class="position-relative arrow-circle  rounded-circle d-block">
          <div class="swiper-button-next" :class="'btn-' + name"></div>
        </button>
        <button class="position-relative arrow-circle   rounded-circle d-block mr-2">
          <div class="swiper-button-prev" :class="'btn-' + name"></div>
        </button>
      </div>
    </div>
    <div v-if="!disabledSliders.includes(name)" class="swiper-container py-3 px-sm-3 mb-2">
      <div class="swiper-wrapper">
        <div v-for="(item, index) in products" :key="index" class="swiper-slide discounted-products-item">
          <product :product="item" :newTab="true"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import product from '../product'
import Swiper from "swiper/swiper-bundle.js";

export default {
  name: "Recommendation",
  components: {
    product
  },
  props: {
    title1: {
      type: String,
      default: 'منتخب'
    },
    title2: {
      type: String,
      default: 'تخفیف دارها'
    },
    products: Array,
    name: String
  },
  watch: {
    products() {
      this.initSlider(true)
    }
  },
  mounted() {
    this.initSlider()
  },
  methods: {
    initSlider(kill = false) {
      this.initSwiper(this.name, kill, () => {
        if (this.swiper) {
          this.swiper.destroy();
        }
        this.swiper = new Swiper('.' + this.name + ' .swiper-container', {
          spaceBetween: 15,
          //   centeredSlides: true,
          loop: false,
          speed: 800,
          autoplay: {
            delay: 3000,
            disableOnInteraction: false,
          },
          navigation: {
            nextEl: '.btn-' + this.name + '.swiper-button-next',
            prevEl: '.btn-' + this.name + '.swiper-button-prev',
          },
          breakpoints: {
            1: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            570: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            571: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
            992: {
              slidesPerView: 4,
              spaceBetween: 30,
            },
            1200: {
              slidesPerView: 5,
              spaceBetween: 30,
            },
          }
        });
      })
    }
  },
}
</script>

<style scoped>

</style>
