<template>
  <section class="products-categories-mobile container mb-sm-4 mb-3 d-flex d-xl-none justify-content-center flex-column">
    <a v-for="banner in $store.state.header.banners.filter(item => item.position == 'a3-mobile')" target="_blank"
       :key="banner.id" :href="banner.link">
      <img v-if="banner.image" :src="banner.image" :alt="banner.image_alt">
      <b-skeleton-img v-else></b-skeleton-img>
    </a>
  </section>
</template>

<script>
export default {
  name: "ManyBannersGridMobile"
}
</script>

<style scoped>
a {
  display: block;
  margin-right: auto;
  margin-left: auto;
}
img {
  border-radius: 10px;
  margin-bottom: 10px;
}
</style>
