<template>
  <div class="d-flex flex-column mt-1 px-3">
    <div class="banner-mobile d-md-none" v-for="banner in $store.state.header.banners.filter(item => item.position == 'a5-mobile')" :key="banner.id">
      <img :src="banner.image">
    </div>
  </div>
</template>

<script>
export default {
  name: "TwoBanner"
}
</script>
<style scoped>
.banner-mobile {
  margin-top: 10px;
}
.banner-mobile img {
  border-radius: 10px;
}
</style>
