<template>
  <section v-if="!disabledSliders.includes('banners')" class="banner-slider container mb-3 mb-sm-4">
    <template v-if="!mediaQueries.mobileSize">
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <template v-if="$store.state.header.banners">
            <div class="swiper-slide"
                 v-for="banner in $store.state.header.banners.filter(item => item.position == 'a1')" :key="banner.id">
              <a target="_blank" :href="banner.link">
                <img v-if="banner.image" :alt="banner.image_alt" :src="banner.image">
                <b-skeleton-img v-else></b-skeleton-img>
              </a>
            </div>
          </template>
        </div>
      </div>
      <div class="swiper-pagination"></div>
    </template>
    <template v-else>
      <a target="_blank" :href="banner.link" class="banner-mobile" v-for="banner in $store.state.header.banners.filter(item => item.position == 'a4-mobile')" :key="banner.id">
        <img :src="banner.image">
      </a>
    </template>
  </section>
</template>

<script>
export default {
  name: "BannersSlider",
  inject: ['mediaQueries'],

}
</script>

<style scoped>
.banner-mobile {
  margin-top: 10px;
  display: inline-block;
}
.banner-mobile img {
  border-radius: 10px;
}
</style>
