<template>
  <section
    class="best-sellers discounted-products mb-3 mb-sm-5 pt-4 pb-md-0 pb-4"
  >
    <div id="newBack"></div>
    <div class="container d-flex justify-content-between mb-3 d-md-none">
      <div class="mt-1">
        <!-- <img src="../../assets/images/circle.png" alt=""> -->
        <!-- <div class="circle1 rounded-circle mt-1 d-inline-block"></div> -->
        <h3 class="d-inline-block" style="position: relative;">
          <span class="weight-bold text-white fontsize16">پرفروش ترین ها</span>
        </h3>
      </div>
      <div class="line mx-2 mt-3"></div>
      <div class="d-flex mt-1">
        <button class="position-relative arrow-circle  rounded-circle d-block">
          <div class="swiper-button-next"></div>
        </button>
        <button class="position-relative arrow-circle   rounded-circle d-block mr-2">
          <div class="swiper-button-prev"></div>
        </button>
      </div>
    </div>
    <div class=" d-flex justify-content-between container">
      <div
        class="best-sellers-topic d-flex flex-column col-4 text-white text-right align-self-center pt-5 my-xl-0 my-md-auto my-0"
      >
        <h3 class="weight-bold fontsize18">پرفروش ترین ها</h3>
        <h4 class="fontsize11 mt-n2 pt-2" style="opacity: 0.8">
          Best selling products
        </h4>
        <p class="fontsize12 text-justify">فروشگاه آلبا</p>
        <div class="d-flex justify-content-between mt-5 mb-xl-4 pt-xl-3 pt-5">
          <router-link
            to="/products?sort=top_sales"
            class="best-sellers-more-btn rounded-pill  border py-2 px-3 fontsize12  text-color-999"
          >
            محصولات بیشتر
          </router-link>
          <div class="d-flex mt-1">
            <button class="position-relative arrow-circle  rounded-circle d-block">
              <div class="swiper-button-next"></div>
            </button>
            <button
              class="position-relative arrow-circle   rounded-circle d-block mr-2"
            >
              <div class="swiper-button-prev"></div>
            </button>
          </div>
        </div>
      </div>
      <div
        v-if="!disabledSliders.includes('bestSeller')"
        class="col-md-8 mb-3 mb-sm-0 p-0"
      >
        <div class="swiper-container py-3 mb-4">
          <div class="swiper-wrapper">
            <div
              v-for="(item, index) in mostSoldProducts"
              :key="index"
              class="swiper-slide discounted-products-item"
            >
              <product :product="item" :newTab="true"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <router-link
      to="/products?sort=top_sales"
      class="best-sellers-more-btn d-md-none rounded-pill bg-white border py-1 px-3 mt-4 fontsize12 text-color-999"
    >
      محصولات بیشتر
    </router-link>
  </section>
</template>

<script>
import Product from "../product";
export default {
  name: "BestSellers",
  components: { Product },
  props: {
    mostSoldProducts: Array,
  },
};
</script>

<style scoped>
.best-sellers #newBack {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 70%;
  background: var(--color-themeBlue);
}
.best-sellers {
  position: relative;
}
.best-sellers-more-btn{
  background: var(--color-themeBlue) !important;
  color: white;
}

</style>
