<template>
  <section v-if="discountProducts && discountProducts.data.length"
           class="discounted-products discounted-products1 container mb-4 mb-sm-5 mt-4 mt-sm-5">
    <div class="d-flex justify-content-between mb-1 mb-sm-4">
      <div class="mt-1">
        <img src="../../../../assets/images/circle.png" alt="">
        <!-- <div class="circle1 rounded-circle mt-1 d-inline-block"></div> -->
        <h3 style="font-size: 1.25rem" class="d-inline-block"><span class="weight-bold text-color-444 pr-2">آف روز</span> <span
            class="text-color-themeBlue fontsize15 font-weight-bolder weight-bold"></span></h3>
      </div>
      <div class="line mx-2 mt-3"></div>
      <div class="d-flex mt-1">
        <button class="position-relative arrow-circle  rounded-circle d-block">
          <div class="swiper-button-next"></div>
        </button>
        <button class="position-relative arrow-circle   rounded-circle d-block mr-2">
          <div class="swiper-button-prev"></div>
        </button>
      </div>
    </div>

    <div v-if="!disabledSliders.includes('discountProducts')" class="swiper-container py-3 px-0 mb-4">
      <div class="swiper-wrapper">
        <div v-for="(item, index) in discountProducts.data" :key="index"
             class="swiper-slide discounted-products-item">
          <product :product="item" :newTab="true"/>
        </div>
      </div>
    </div>

    <router-link to="/products?discount=true"
                 class="bg-color-themeBlue rounded-pill py-2 px-4 fontsize12 text-white d-table mx-auto">
      محصولات
      بیشتر
    </router-link>
  </section>
</template>

<script>
import Product from "../product";
export default {
  name: "SpecialProducts",
  components: {Product},
  props: {
    discountProducts: Object
  }
}
</script>

<style scoped>

</style>
